import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IShellReduxState } from "../../shell/shellReduxRegistration";
import * as Actions from "../../shell/layout/redux/actions";
import { Prompt } from "react-router";

/* 
  Defines a Dirty State Scope
  - when something is marked as dirty, react navigation is blocked
  - multiple dirty state scopes can exist on a single screen
  
  Limitations:
  - button presses are not blocked, as how they will behave are up to the screen design
    in the situation where there are multiple tasks on the one screen, completing one task does not mean
    other tasks cannot be completed immediately afterwards without losing their state.
    navigation will however remain blocked until all tasks are completed
  - browser navigation and refresh cannot be blocked, this is a technical limitation of react
    (yes, more complicated solutions could be employed, for minimal benefit...)
*/

interface IDirtyScopeProps extends Actions.IActionFactory {
    scopeName: string;
    isDirty: boolean;
    children?: React.ReactNode;
}

/**
// @deprecated use TaskLayout instead
*/
function DirtyScopeController(props: IDirtyScopeProps) {
    const message = "There are unsaved changes. Are you sure you wish to continue?";

    // detect the change in dirty state
    // when it is marked as dirty, raise the redux message to enlist the scope as dirty
    const [isDirty, setIsDirty] = useState(props.isDirty);

    function synchroniseRedux() {
        if (props.isDirty) props.setDirty(props.scopeName);
        else props.clearDirty(props.scopeName);
    }

    // on mount
    useEffect(() => {
        synchroniseRedux();

        // - clear dirty state on unmount
        return function () {
            props.clearDirty(props.scopeName);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.isDirty !== isDirty) {
            setIsDirty(props.isDirty);
            synchroniseRedux();
        }
    }, [props.isDirty, isDirty, props]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {props.children}
            <Prompt when={isDirty} message={message} />
        </>
    );
}

// connect redux
/**
// @deprecated use TaskLayout instead
*/
export default connect((state: IShellReduxState) => ({}), Actions.actionFactory)(DirtyScopeController);

/*
    // keeping this around in case we decide we think we can solve it, but then realise we can't  ;)
    // unfortunately the 'isdirty' and 'props.isdirty' values will always hold the old state as at render time

    // on unload
    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);

        return function () {
            window.removeEventListener("beforeunload", handleUnload);
        }

        function handleUnload(e: any): any {
            console.log(props.isDirty);
            console.log(isDirty);
            e.preventDefault();
            (e || window.event).returnValue = message; //Gecko + IE
            return message;
        }
    }, []);
*/
