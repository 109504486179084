// framework
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// redux
import * as Models from "./models/models";
import * as actions from "./actions";
// api
import { GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineItemDto } from "../../../../api/Client";
// common
import NavBar, { TabsEnum } from "../components/views/NavBarView";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
import * as GeoscienceAustraliaRouteHelper from "../../../geoscienceAustraliaRouteHelper";
import PageLayout from "../../../../common/shell/PageLayout";
import TaskLayout from "../../../../common/shell/TaskLayout";
import * as toastHelper from "../../../../common/toastHelper";
import LinksView, { LinkDetail } from "../../../common/links/LinksView";
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
// models
import validator from "./models/validator";
// views
import SeismicLineListGridView from "./views/SeismicLineListGridView";
import EditSeismicLineView from "./views/EditSeismicLineView";

interface IRouteParameters {
    surveyId: string;
}

export default function Controller(): JSX.Element {
    const params = useParams<IRouteParameters>();
    const { surveyId } = params;

    let [viewModel, setViewModel] = useState<Models.IRootViewModel>(new Models.RootViewModel(Number(surveyId)));

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(viewModel, validationVisibility);

    // on mount
    useEffect(() => {
        actions.getSeismicLines(viewModel, setViewModel);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onExport = () => {
        actions.exportSeismicLines(viewModel, setViewModel);
    };

    const onCreateShow = () => {
        setViewModel(
            viewModel
                .refreshViewState(Models.ViewStateEnum.Editing)
                .refreshSelectedSurveySeismicLine(new Models.SeismicLineItemViewModel(new GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineItemDto()))
                .refreshStatusMessages(undefined)
        );
    };

    const onSave = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        if (viewModel.selectedSeismicLine?.id === undefined) actions.createSeismicLine(viewModel, setViewModel);
        else actions.updateSeismicLine(viewModel, setViewModel);
    };

    const onDelete = (item: Models.ISeismicLineItemViewModel) => {
        actions.deleteSeismicLine(viewModel.refreshSelectedSurveySeismicLine(item), setViewModel);
    };

    return (
        <>
            {viewModel.surveyName && viewModel.surveyAcquisitions && (
                <PageLayout
                    title={[`Survey - ${viewModel.surveyName}`]}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys}
                    onReloadRequested={() => actions.getSeismicLines(viewModel, setViewModel)}
                >
                    <NavBar surveyId={Number(surveyId)} activeNavItem={TabsEnum.SeismicLines} />
                    <TaskLayout isBusy={viewModel.isBusy} statusMessages={viewModel.statusMessages} isConflict={viewModel.isConflict} isDirty={viewModel.isDirty}>
                        <LinksView
                            title="Acquisition(s)"
                            canViewLink={true}
                            linkTooltip="Click to view Acquisition Survey Data"
                            links={viewModel.surveyAcquisitions.map(
                                (surveyAcquisition) => new LinkDetail(surveyAcquisition.name, GeoscienceAustraliaRouteHelper.acquisitionSurveyData(viewModel.surveyId!, surveyAcquisition.id))
                            )}
                        />
                        <SeismicLineListGridView
                            viewModel={viewModel}
                            onEdit={(item: Models.ISeismicLineItemViewModel) => {
                                setViewModel(viewModel.refreshViewState(Models.ViewStateEnum.Editing).refreshSelectedSurveySeismicLine(item));
                            }}
                            onDelete={(item) => {
                                onDelete(item);
                            }}
                        />

                        {viewModel.viewState === Models.ViewStateEnum.Editing && (
                            <EditSeismicLineView
                                viewModel={viewModel}
                                setViewModel={setViewModel}
                                v={v}
                                onSave={() => onSave()}
                                onCancelUpdate={() => setViewModel(viewModel.refreshViewState(Models.ViewStateEnum.View))}
                            />
                        )}

                        <div className="container-fluid p-2 form">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary me-2" onClick={onCreateShow} title="Create Seismic Line">
                                    Create
                                </button>
                                <button type="button" className="btn btn-primary me-2" onClick={onExport} title="Export Seismic Lines to Excel">
                                    Export
                                </button>
                            </div>
                        </div>
                    </TaskLayout>
                </PageLayout>
            )}
        </>
    );
}
