import { IRootViewModel } from "./models";
import * as Validation from "../../../../../common/validation/ValidationModel";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (!vm.importAttachmentFile || Validation.isNullOrWhiteSpace(vm.importAttachmentFile.fileName) || !vm.importAttachmentFile.fileContent) {
        v.addError("bulkImport.ImportFile", "File must be supplied.");
        return v;
    }

    return v;
}
