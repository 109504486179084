// framework
import React, { useState, ReactNode, useEffect } from "react";
import { connect } from "react-redux";
// view
import { SyncIcon } from "@primer/octicons-react";
import ConfirmationView, { DefaultButtonEnum } from "../confirmation/ConfirmationView";
// models & actions
import * as Actions from "../../shell/layout/redux/actions";
import { Level1ItemTypeEnum } from "../../shell/layout/navigationItemTypes";
import { IRootReduxState } from "../../infrastructure/reduxRootReducer";
import * as Models from "../../shell/layout/models/rootViewModel";

interface IBodyLayoutProps extends Actions.IActionFactory {
    rootVm: Models.IRootViewModel;
    children: ReactNode;
    title: string;
    customTitle?: React.ReactNode | undefined;
    showPageHeader?: boolean | undefined;
    includePadding?: boolean | undefined;
    selectedLhsNavItem?: Level1ItemTypeEnum | undefined;
    // reload is only available if you supply an on-reload delegate
    onReloadRequested?: (() => void) | undefined;
}

/**
// @deprecated use PageLayout instead
*/
function BodyLayoutController(props: IBodyLayoutProps) {
    let title = props.title;
    if (title.length > 100) title = title.substring(0, 97) + "...";

    const customTitle = props.customTitle;
    const children = props.children;
    const showPageHeader = props.showPageHeader ?? true;
    const includePadding = props.includePadding ?? true;

    // initial load
    useEffect(() => {
        props.updateBodyLayout(props.title, showPageHeader, includePadding, props.selectedLhsNavItem);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function renderHeaderContent(): React.ReactElement {
        if (!showPageHeader) return <></>;

        return (
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between p-0 m-0">
                        {/* title */}
                        {customTitle ? customTitle : <h1 className="d-inline">{title}</h1>}

                        {/* reload */}
                        <ReloadView isDirty={props.rootVm.isDirty} onReloadRequested={props.onReloadRequested} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-0">
            {/* header */}
            {renderHeaderContent()}

            {/* content */}
            <div className="row">
                {/* children */}
                <div className="col">{children}</div>
            </div>
        </div>
    );
}

function ReloadView(props: { isDirty: boolean; onReloadRequested?: (() => void) | undefined }): React.ReactElement {
    const [showConfirmation, setShowConfirmation] = useState(false);

    // reload isn't applicable to this screen
    if (!props.onReloadRequested) return <></>;

    // reload is applicable and the screen isn't dirty
    if (!props.isDirty) {
        return renderButton(() => props.onReloadRequested!());
    }

    // reload is applicable and the screen is dirty
    if (!showConfirmation) {
        return renderButton(() => {
            setShowConfirmation(true);
        });
    }

    return (
        <ConfirmationView
            title={"Reload Page"}
            onAccepted={() => {
                setShowConfirmation(false);
                props.onReloadRequested!();
            }}
            onClosed={() => setShowConfirmation(false)}
            defaultButton={DefaultButtonEnum.Ok}
        >
            There are unsaved changes. Are you sure you wish to continue?
        </ConfirmationView>
    );

    function renderButton(onClicked: () => void) {
        return (
            <button className="btn btn-outline-primary d-inline" style={{ height: "100%" }} title="Reload Page" onClick={onClicked}>
                <SyncIcon size="medium" />
            </button>
        );
    }
}

// connect redux
/**
// @deprecated use PageLayout instead
*/
export default connect((state: IRootReduxState) => ({ rootVm: state.Shell_Layout }), Actions.actionFactory)(BodyLayoutController);
