import { useEffect, useState } from "react";
import { orderBy } from "@progress/kendo-data-query";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import * as Client from "../../api/Client";
import * as GlobalHelpers from "../GlobalHelpers";
import SimpleAlertView from "../simpleAlerts/SimpleAlertView";
import SimpleDataAuditChangeView from "./controls/SimpleDataAuditChangeView";

interface IProps {
    className?: string | undefined;
    changes: Client.SecurePortalSimpleDataAuditChangesDto | undefined;
}

export default function SimpleDataAuditChangesView(props: IProps) {
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [filterText, setFilterText] = useState<string>("");

    useEffect(() => {
        setSelectedIndex(-1);
        setFilterText("");
    }, [props.changes]);

    if (!props?.changes?.changes) return <></>;
    if (props.changes.changes.length === 0)
        return (
            <>
                <div className={props.className}>
                    <SimpleAlertView alertType="information" dismissable={false} message="No auditing information available." />
                </div>
            </>
        );

    const snapshotNodeIncludesValue = (snapshotNode: Client.SimpleDataAuditSnapshotModelNodeDto | undefined, value: string) => {
        if (!snapshotNode) return false;
        if (snapshotNode.key?.toUpperCase().includes(value)) return true;
        if (snapshotNode.attributes && snapshotNode.attributes.some((a) => a.name?.toUpperCase().includes(value) || a.value?.toUpperCase().includes(value))) return true;
        if (snapshotNode.nodes && snapshotNode.nodes.some((n) => snapshotNodeIncludesValue(n, value))) return true;
        return false;
    };

    const deltaNodeIncludesValue = (deltaNode: Client.SimpleDataAuditDeltaModelNodeDto | undefined, value: string) => {
        if (!deltaNode) return false;
        if (deltaNode.key?.toUpperCase().includes(value)) return true;
        if (deltaNode.originalAttributes && deltaNode.originalAttributes.some((a) => a.name?.toUpperCase().includes(value) || a.value?.toUpperCase().includes(value))) return true;
        if (deltaNode.newAttributes && deltaNode.newAttributes.some((a) => a.name?.toUpperCase().includes(value) || a.value?.toUpperCase().includes(value))) return true;
        if (deltaNode.nodes && deltaNode.nodes.some((n) => deltaNodeIncludesValue(n, value))) return true;
        return false;
    };

    const changes = orderBy(
        props.changes!.changes!.filter(
            (c) =>
                c.identity?.toUpperCase().includes(filterText.trim().toUpperCase()) ||
                snapshotNodeIncludesValue(c.snapshot, filterText.trim().toUpperCase()) ||
                deltaNodeIncludesValue(c.delta, filterText.trim().toUpperCase())
        ),
        [{ field: "timestamp", dir: "desc" }]
    );

    return (
        <div className={props.className}>
            <div className="row my-2">
                <div className="col-3">
                    <input
                        id="filterText"
                        className="form-control"
                        maxLength={100}
                        type="text"
                        placeholder="Filter"
                        value={filterText}
                        onChange={(e) => {
                            setSelectedIndex(-1);
                            setFilterText(e.target.value);
                        }}
                    />
                </div>
            </div>
            {changes.map((change, index) => {
                const timestamp = GlobalHelpers.toNoptaDatetimeString(change.timestamp);
                let message;
                if (!change.identity) message = `${timestamp} (AWST)`;
                else message = `${change.identity} on ${timestamp} (AWST)`;

                return (
                    <ExpansionPanel
                        key={index.toString()}
                        title={message}
                        expanded={selectedIndex === index}
                        onAction={(event) => {
                            setSelectedIndex(event.expanded ? -1 : index);
                        }}
                    >
                        <Reveal>
                            {selectedIndex === index && (
                                <ExpansionPanelContent>
                                    <SimpleDataAuditChangeView change={change} />
                                </ExpansionPanelContent>
                            )}
                        </Reveal>
                    </ExpansionPanel>
                );
            })}
        </div>
    );
}
