// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (vm.selectedSeismicLine && !vm.selectedSeismicLine.surveyAcquisition) {
        v.addError("Acquisition", "Acquisition is required.");
    }

    if (vm.selectedSeismicLine && Validation.isNullOrWhiteSpace(vm.selectedSeismicLine.lineName)) {
        v.addError("LineName", "Line Name is required.");
    }

    return v;
}
