// framework
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// common
import { actionFactory } from "../../shell/layout/redux/actions";
import StatusMessagesAlertsView from "../alerts/StatusMessagesAlertsView";
import ConflictView from "../alerts/ConflictAlertView";
// client
import { StatusMessagesDto } from "../../api/Client";
import { Prompt } from "react-router-dom";

interface IProps {
    isConflict?: boolean | undefined;
    isDirty?: boolean | undefined;
    isBusy?: boolean | undefined;
    statusMessages?: StatusMessagesDto | undefined;
    statusMessagesScrollToTop?: boolean | undefined;
    children: React.ReactNode;
    className?: string | undefined;
}

export default function TaskLayout(props: IProps): JSX.Element {
    return (
        <>
            <BusyState isBusy={props.isBusy} />
            <DirtyState isDirty={props.isDirty} />
            <div className={props.className}>
                {props.isConflict && <ConflictView isConflict={props.isConflict} />}
                {props.statusMessages && <StatusMessagesAlertsView statusMessages={props.statusMessages} scrollWindowToTop={props.statusMessagesScrollToTop} />}
                {props.children}
            </div>
        </>
    );
}

function BusyState(props: { isBusy: boolean | undefined }): JSX.Element {
    const isBusy = props.isBusy;
    const isBusyState = useRef<boolean>(isBusy ?? false);
    const dispatch = useDispatch();

    useEffect(() => {
        // when wired up, can only be true/false, undefined means this feature is not being used
        if (isBusy === undefined) return;

        // nothing has changed
        if (isBusy === isBusyState.current) return;

        // changed: flip the shell's busy state
        isBusyState.current = isBusy;
        if (isBusy!) {
            dispatch(actionFactory.setBusy());
        } else {
            dispatch(actionFactory.clearBusy());
        }
    }, [isBusy]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // clear on unmount
        return () => {
            if (isBusyState.current) {
                dispatch(actionFactory.clearBusy());
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
}

function DirtyState(props: { isDirty: boolean | undefined }): JSX.Element {
    const message = "There are unsaved changes. Are you sure you wish to continue?";
    const isDirty = props.isDirty;
    const isDirtyState = useRef<boolean>(isDirty ?? false);
    const scopeName = useRef<string>(crypto.randomUUID());
    const dispatch = useDispatch();

    useEffect(() => {
        // when wired up, can only be true/false, undefined means this feature is not being used
        if (isDirty === undefined) return;

        // nothing has changed
        if (isDirty === isDirtyState.current) return;

        // changed: flip the shell's dirty state
        isDirtyState.current = isDirty;
        if (isDirty) {
            dispatch(actionFactory.setDirty(scopeName.current));
        } else {
            dispatch(actionFactory.clearDirty(scopeName.current));
        }
    }, [isDirty]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // clear on unmount
        const currentScopeName = scopeName.current;
        return () => {
            if (isDirtyState.current) {
                dispatch(actionFactory.clearDirty(currentScopeName));
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <Prompt when={isDirty} message={message} />;
}
