// api
import * as Client from "../../../../../../../api/Client";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
// views
import DataMonitoringItemsCategoryView from "./DataMonitoringItemsCategoryView";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function SampleViewCategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <>
            <Forms.Row>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="sampleDepthField" label="Sample Depths" value={category.sampleViewDetails?.sampleDepth} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea id="sampleTypeField" label="Sample Type(s)" value={category.sampleViewDetails?.sampleTypeNames.join(", ")} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="repositoryField" label="Repository" value={category.sampleViewDetails?.repositoryTypeName} />
                </Forms.HalfColumn>
            </Forms.Row>
            <DataMonitoringItemsCategoryView
                activityType={activityType}
                category={category}
                isExportCategory={false}
                canViewBoreholes={canViewBoreholes}
                canViewAcquisitions={canViewAcquisitions}
                canViewProjects={canViewProjects}
                canViewDataSubmissions={canViewDataSubmissions}
            />
        </>
    );
}
