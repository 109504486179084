import * as Client from "../../../../api/Client";
import { IPaymentViewModel } from "./paymentViewModel";
import { clone } from "lodash";

export interface IRootViewModel {
    isDirty: boolean;
    isBusy: boolean;
    isConflict: boolean;

    financialNotificationId: number;
    financialNotification: Client.GetCompanyFinanceFnDetailsResponseDto;
    refreshDetails(response: Client.GetCompanyFinanceFnDetailsResponseDto): IRootViewModel;

    refreshIsBusy(isBusy: boolean): IRootViewModel;
    refreshConflict(): IRootViewModel;

    paymentViewModel: IPaymentViewModel | undefined;
    refreshPaymentDetails(values: IPaymentViewModel): IRootViewModel;
    refreshCreditCardSurcharge(response: Client.GetCompanyFinanceCreditCardSurchargeResponseDto): IRootViewModel;

    showMakePayment: boolean;
    setShowMakePayment(show: boolean): IRootViewModel;

    service: {
        statusMessages?: Client.StatusMessagesDto;
    };
    setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor(financialNotificationId: number) {
        this.isDirty = false;
        this.isBusy = false;
        this.isConflict = false;
        this.financialNotificationId = financialNotificationId;
        this.financialNotification = new Client.GetCompanyFinanceFnDetailsResponseDto();
        this.showMakePayment = false;
        this.paymentViewModel = undefined;
        this.service = {
            statusMessages: undefined,
        };
    }

    public isDirty: boolean;
    public isBusy: boolean;
    public isConflict: boolean;
    public financialNotificationId: number;
    public financialNotification: Client.GetCompanyFinanceFnDetailsResponseDto;
    public paymentViewModel: IPaymentViewModel | undefined;
    public showMakePayment: boolean;

    public service: {
        statusMessages?: Client.StatusMessagesDto;
    };

    public refreshDetails(response: Client.GetCompanyFinanceFnDetailsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.isDirty = false;
        vm.isConflict = false;
        vm.financialNotification = response;
        vm.showMakePayment = false;
        vm.paymentViewModel = undefined;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.service.statusMessages = undefined;
        return vm;
    }

    public refreshPaymentDetails(values: IPaymentViewModel): IRootViewModel {
        const vm = this._clone();
        vm.paymentViewModel = values;
        vm.isDirty = true;
        return vm;
    }

    public refreshCreditCardSurcharge(response: Client.GetCompanyFinanceCreditCardSurchargeResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.paymentViewModel!.creditCardSurchargeAmount = response.surchargeAmount;
        vm.service.statusMessages = response.statusMessages;
        return vm;
    }

    public setStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.service.statusMessages = statusMessages;
        return vm;
    }

    public setShowMakePayment(show: boolean): IRootViewModel {
        const vm = this._clone();
        vm.showMakePayment = show;
        vm.paymentViewModel = undefined;
        vm.isDirty = false;
        vm.service.statusMessages = undefined;
        return vm;
    }

    refreshIsBusy(isBusy: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isBusy = isBusy;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.financialNotification = clone(this.financialNotification);
        vm.paymentViewModel = clone(this.paymentViewModel);
        vm.service = clone(this.service);

        return vm;
    }
}
