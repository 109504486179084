// framework
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// redux
import * as Models from "./models/models";
import * as actions from "./actions";
// shell
import { Level1ItemTypeEnum } from "../../../shell/layout/navigationItemTypes";
// views
import FinancialNotificationDetailsView from "../common/components/FinancialNotificationDetailsView";
import MakePaymentView from "./views/MakePaymentDetailsView";
// other
import SimpleAuditEventsView from "../../../common/audit/SimpleAuditEventsView";
import PageLayout from "../../../common/shell/PageLayout";
import TaskLayout from "../../../common/shell/TaskLayout";

// this is the parameters passed on the route.
interface IFinancialNotificationDetailsRouteParameters {
    id: string;
}

export default function Controller(): React.ReactElement {
    const params = useParams<IFinancialNotificationDetailsRouteParameters>();
    const financialNotificationId = parseInt(params.id);
    let [viewModel, setViewModel] = useState<Models.IRootViewModel>(new Models.RootViewModel(financialNotificationId));

    // on mount
    useEffect(() => {
        actions.getFinancialNotificationDetails(viewModel, setViewModel);
    }, [financialNotificationId]); // eslint-disable-line react-hooks/exhaustive-deps

    let title = "Financial Notification Details";
    if (viewModel.financialNotification?.financialNotificationCode) {
        title = viewModel.financialNotification.financialNotificationTypeDisplay + " - " + viewModel.financialNotification.financialNotificationCode;
    }

    return (
        <PageLayout
            title={[title]}
            onReloadRequested={() => actions.getFinancialNotificationDetails(viewModel, setViewModel)}
            selectedLhsNavItem={Level1ItemTypeEnum.Company_Finance_FinancialNotificationList}
        >
            <TaskLayout isBusy={viewModel.isBusy} isDirty={viewModel.isDirty} isConflict={viewModel.isConflict} statusMessages={viewModel.service.statusMessages}>
                <div className="card my-2">
                    <FinancialNotificationDetailsView vm={viewModel.financialNotification} />
                </div>
                {viewModel.financialNotification?.canPay && !viewModel.showMakePayment && (
                    <>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-outline-primary" onClick={(_) => setViewModel(viewModel.setShowMakePayment(true))}>
                                    Make/Record Payment
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {viewModel.showMakePayment && (
                    <div className="card my-2">
                        <MakePaymentView viewModel={viewModel} setViewModel={setViewModel} />
                    </div>
                )}

                <SimpleAuditEventsView className="mt-4" simpleAuditEvents={viewModel.financialNotification?.audit} />
            </TaskLayout>
        </PageLayout>
    );
}
