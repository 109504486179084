// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
// redux
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import * as toastHelper from "../../../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import SurveyDataLinesListGridView from "./views/SurveyDataLinesListGridView";
import SurveyDataLineView from "./views/SurveyDataLineView";
import validator from "../models/validator";
import importValidator from "../models/importValidator";
import DownloadLinkView from "../../../../../common/externalLink/DownloadLinkView";
import { KendoSingleSmallFileUpload } from "../../../../../common/fileUpload/KendoSingleSmallFileUpload";
import { Alert } from "react-bootstrap";
import { InfoIcon } from "@primer/octicons-react";

interface IRouteParameters {
    surveyDataId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyDataId } = params;

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);
    const [enableImportButton, setEnableImportButton] = useState(false);

    const [deleteItem, setDeleteItem] = useState<{ surveyDataLineId?: number }>();
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const [importValidationVisibility, setImportValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);
    const importValidation = importValidator(vm, importValidationVisibility);

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyDataId));
        return function () {
            actions.clearRequested();
            setEnableImportButton(false);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onCreateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.addRequested(vm);
        window.scrollTo(0, 0);
    };

    const onUpdateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.updateRequested(vm);
        window.scrollTo(0, 0);
    };

    const onCreateCancelClicked = () => {
        actions.viewChanged(vm.setViewState(Models.ViewStateEnum.View).clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateCancelClicked = () => {
        actions.viewChanged(vm.setViewState(Models.ViewStateEnum.View).clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateShow = (saveSurveyDataLine: Models.ISaveSurveyDataLine) => {
        actions.viewChanged(vm.setViewState(Models.ViewStateEnum.Edit).clearStatusMessages().onSaveSurveyDataLineChanged(saveSurveyDataLine));
    };

    const onDeleteShow = (surveyDataLineId: number) => {
        setShowDeleteConfirmation(true);
        setDeleteItem({ surveyDataLineId: surveyDataLineId });
    };

    const onDeleteCancel = () => {
        setDeleteItem({});
        setShowDeleteConfirmation(false);
    };

    const onDeleteAccept = () => {
        actions.deleteRequested(vm.surveyDataId!, vm.surveyDataVersionNumber!, deleteItem!.surveyDataLineId!);
        setShowDeleteConfirmation(false);
    };

    const onDeleteAllShow = () => {
        setShowDeleteAllConfirmation(true);
    };
    const onDeleteAllCancel = () => {
        setShowDeleteAllConfirmation(false);
    };

    const onDeleteAllAccept = () => {
        actions.deleteAllRequested(vm.surveyDataId!, vm.surveyDataVersionNumber!);
        setShowDeleteAllConfirmation(false);
    };

    function onImportClicked() {
        if (!importValidation.isValid()) {
            setImportValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        actions.uploadSurveyDataLinesFile(vm);
        actions.viewChanged(vm.refreshImportSurveyDataLinesFile());
        setEnableImportButton(false);
    }

    function onCancelClicked() {
        actions.viewChanged(vm.refreshStatusMessages().setViewState(Models.ViewStateEnum.View).refreshImportSurveyDataLinesFile());
    }

    function onAttachmentFileChange(fileName: string | undefined, content?: string | undefined) {
        actions.viewChanged(vm.refreshImportSurveyDataLinesFile({ fileName: fileName!, fileContent: content! }));
        setEnableImportButton(true);
    }

    return (
        <>
            {vm.viewState !== Models.ViewStateEnum.BulkImport && vm.lines && vm.publicTitle && (
                <BodyLayout
                    title={`Survey Data - ${vm.pageHeader}`}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyDataId));
                    }}
                >
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <NavBar surveyDataId={Number(surveyDataId)} activeNavItem={TabsEnum.Lines} />
                    <SurveyDataLinesListGridView vm={vm} onDelete={onDeleteShow} onEdit={onUpdateShow} />
                    <div className="mt-3">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                actions.viewChanged(vm.setViewState(Models.ViewStateEnum.Add).clearStatusMessages());
                            }}
                        >
                            Create
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                actions.viewChanged(vm.clearStatusMessages());
                                onDeleteAllShow();
                            }}
                        >
                            Delete All
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => actions.viewChanged(vm.setViewState(Models.ViewStateEnum.BulkImport))}>
                            Bulk Import
                        </button>
                    </div>
                </BodyLayout>
            )}

            {vm.viewState === Models.ViewStateEnum.Add && <SurveyDataLineView vm={vm} v={v} actions={actions} onSave={onCreateClicked} onCancel={onCreateCancelClicked} />}
            {vm.viewState === Models.ViewStateEnum.Edit && <SurveyDataLineView vm={vm} v={v} actions={actions} onSave={onUpdateClicked} onCancel={onUpdateCancelClicked} />}
            {showDeleteConfirmation && (
                <ConfirmationView title="Delete Survey Data Line" onAccepted={onDeleteAccept} onClosed={onDeleteCancel} defaultButton={DefaultButtonEnum.Cancel}>
                    <p>Are you sure you want to delete this Survey Data Line?</p>
                </ConfirmationView>
            )}
            {showDeleteAllConfirmation && (
                <ConfirmationView title="Delete All Survey Data Lines" onAccepted={onDeleteAllAccept} onClosed={onDeleteAllCancel} defaultButton={DefaultButtonEnum.Cancel}>
                    <p>Are you sure you want to delete all Lines for this Survey Data?</p>
                </ConfirmationView>
            )}

            {vm.viewState === Models.ViewStateEnum.BulkImport && (
                <BodyLayout title="Survey Data Line Bulk Import" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}>
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <div className="my-2">
                        <Alert variant="warning" className="mb-2">
                            <span className="me-1">
                                <InfoIcon />
                            </span>
                            <span>All existing Lines for this Survey Data will be deleted when importing new Lines from a file.</span>
                        </Alert>
                    </div>
                    <p>
                        Use the template{" "}
                        <DownloadLinkView href="/SurveyDataLineBulkImport.xlsx" title="Click to download the document.">
                            SurveyDataLineBulkImport.xlsx
                        </DownloadLinkView>
                    </p>

                    <h5>File Upload</h5>

                    <KendoSingleSmallFileUpload onFileValidlySelected={onAttachmentFileChange} allowedExtensions={[".xlsx"]} maxFileSizeMb={20} />
                    <ValidationMessageView name="bulkImport.ImportFile" validationModel={importValidation} />

                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            <>
                                <div className="row">
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary" disabled={!enableImportButton} onClick={() => onImportClicked()}>
                                            Import
                                        </button>
                                        <button type="button" className="btn btn-secondary m-2" onClick={() => onCancelClicked()}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataLines }), Actions.actionFactory)(Controller);
