import React, { useEffect, useState } from "react";
import { actionFactory } from "../../shell/layout/redux/actions";
import { useDispatch } from "react-redux";
import { SyncIcon } from "@primer/octicons-react";
import ConfirmationView, { DefaultButtonEnum } from "../confirmation/ConfirmationView";
import { useIsDirty } from "./shellHelper";
import { Level1ItemTypeEnum } from "../../shell/layout/navigationItemTypes";

interface IProps {
    title: (string | undefined)[];
    titleReactNode?: React.ReactNode | undefined;
    navbar?: React.ReactNode | undefined;
    children: React.ReactNode;
    selectedLhsNavItem?: Level1ItemTypeEnum | undefined;
    onReloadRequested?: (() => void) | undefined;
}

// note: the 'selectedLhsNavItem' can be deprecated once we move the LHS menu to a route based system that auto-selects the appropriate item

export default function PageLayout(props: IProps): JSX.Element {
    const dispatch = useDispatch();
    const isDirty = useIsDirty();

    let titleDisplay = props.title.filter((t) => t !== undefined && t !== "").join(" - ");
    if (titleDisplay.length > 100) titleDisplay = titleDisplay.substring(0, 97) + "...";

    useEffect(() => {
        // titleDisplay: pass through the browser tab header
        // includePadding: true
        // showPageHeader: false - we provide a page header solution here
        dispatch(actionFactory.updateBodyLayout(titleDisplay, false, true, props.selectedLhsNavItem));
    }, [titleDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between p-0 m-0">
                        {/* title */}
                        {props.titleReactNode ? props.titleReactNode : <h1>{titleDisplay}</h1>}

                        {/* reload */}
                        <ReloadView isDirty={isDirty} onReloadRequested={props.onReloadRequested} />
                    </div>
                </div>
            </div>

            {props.navbar}
            {props.children}
        </div>
    );
}

function ReloadView(props: { isDirty: boolean; onReloadRequested?: (() => void) | undefined }): React.ReactElement {
    const [showConfirmation, setShowConfirmation] = useState(false);

    // reload isn't applicable to this screen
    if (!props.onReloadRequested) return <></>;

    // reload is applicable and the screen isn't dirty
    if (!props.isDirty) {
        return renderButton(() => props.onReloadRequested!());
    }

    // reload is applicable and the screen is dirty
    if (!showConfirmation) {
        return renderButton(() => {
            setShowConfirmation(true);
        });
    }

    return (
        <ConfirmationView
            title={"Reload Page"}
            onAccepted={() => {
                setShowConfirmation(false);
                props.onReloadRequested!();
            }}
            onClosed={() => setShowConfirmation(false)}
            defaultButton={DefaultButtonEnum.Ok}
        >
            There are unsaved changes. Are you sure you wish to continue?
        </ConfirmationView>
    );

    function renderButton(onClicked: () => void) {
        return (
            <button className="btn btn-outline-primary d-inline" style={{ height: "100%" }} title="Reload Page" onClick={onClicked}>
                <SyncIcon size="medium" />
            </button>
        );
    }
}
