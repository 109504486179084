// framework
import { useState } from "react";
import DirtyScope from "../../../../common/shell/DirtyScopeController";
// redux
import * as Models from "../models/models";
import validator from "../models/validator";
// api
import * as Client from "../../../../api/Client";
// views
import MakeChequePaymentView from "./MakeChequePaymentDetailsView";
import MakeCreditCardPaymentView from "./MakeCreditCardPaymentDetailsView";
import MakeDirectDepositPaymentView from "./MakeDirectDepositPaymentDetailsView";
// common
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as toastHelper from "../../../../common/toastHelper";
import * as actions from "../actions";

export default function MakePaymentDetailsView(props: { viewModel: Models.IRootViewModel; setViewModel: (vm: Models.IRootViewModel) => void }): React.ReactElement {
    let vm = props.viewModel;

    const isDdRadioValue = Client.Payment2MethodEnum.DirectDeposit;
    const isChequeRadioValue = Client.Payment2MethodEnum.Cheque;
    const isCcRadioValue = Client.Payment2MethodEnum.CreditCard;

    const [paymentMethod, setPaymentMethod] = useState(isCcRadioValue);
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.None);

    const v = validator(vm, validationVisibility);

    function onPaymentMethodChanged(paymentMethod: Client.Payment2MethodEnum) {
        setPaymentMethod(paymentMethod);
        if (paymentMethod === Client.Payment2MethodEnum.CreditCard) {
            // todo doesn't follow the UI pattern we have established of showing the validation message first, then
            // removing it once validated. this could be revisited
            setValidationVisibility(ValidationVisibilityEnum.None);
        } else {
            setValidationVisibility(ValidationVisibilityEnum.Messages);
        }
    }

    function onSubmitClicked(e: any) {
        e.preventDefault();
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        actions.createPayment(vm, props.setViewModel);
    }

    return (
        <form onSubmit={onSubmitClicked}>
            <div className="card-body">
                <h2 className="card-title">Choose Payment Method</h2>
                <DirtyScope scopeName="company/finance/financialNotificationDetails" isDirty={vm.isDirty}>
                    <Forms.Group>
                        <Forms.RadioButton
                            name="requestGroup"
                            id="isCcRadioField"
                            label="Credit Card"
                            value={isCcRadioValue}
                            checked={paymentMethod === isCcRadioValue}
                            valid={v.applyValidity("MakePayment.PaymentMethod")}
                            onChange={onPaymentMethodChanged}
                            inline
                        />
                        <Forms.RadioButton
                            name="requestGroup"
                            id="isDdRadioField"
                            label="Direct Deposit"
                            value={isDdRadioValue}
                            checked={paymentMethod === isDdRadioValue}
                            valid={v.applyValidity("MakePayment.PaymentMethod")}
                            onChange={onPaymentMethodChanged}
                            inline
                        />
                        <Forms.RadioButton
                            name="requestGroup"
                            id="isDdRadioField"
                            label="Cheque"
                            value={isChequeRadioValue}
                            checked={paymentMethod === isChequeRadioValue}
                            valid={v.applyValidity("MakePayment.PaymentMethod")}
                            onChange={onPaymentMethodChanged}
                            inline
                        />
                        <ValidationMessageView name="MakePayment.PaymentMethod" validationModel={v} />
                    </Forms.Group>
                    {paymentMethod === isCcRadioValue && <MakeCreditCardPaymentView viewModel={vm} setViewModel={props.setViewModel} validator={v} />}
                    {paymentMethod === isDdRadioValue && <MakeDirectDepositPaymentView viewModel={vm} setViewModel={props.setViewModel} validator={v} />}
                    {paymentMethod === isChequeRadioValue && <MakeChequePaymentView viewModel={vm} setViewModel={props.setViewModel} validator={v} />}
                    <Forms.Group>
                        <button className="btn btn-outline-primary me-2" type="submit" disabled={!vm.isDirty}>
                            Submit
                        </button>
                        <button className="btn btn-outline-secondary me-2" onClick={(e) => props.setViewModel(vm.setShowMakePayment(false))}>
                            Cancel
                        </button>
                    </Forms.Group>
                </DirtyScope>
            </div>
        </form>
    );
}
