import { useSelector, useDispatch } from "react-redux";
import * as layoutActions from "../../shell/layout/redux/actions";
import { IRootReduxState } from "../../infrastructure/reduxRootReducer";
import * as Client from "../../api/Client";

// createXXXAction - actions for sagas
// useXXX - for components

export function useIsSignedIn(): boolean {
    const state = useSelector((state: IRootReduxState) => state.Shell_SignIn);
    return state.isSignedIn;
}

export function useGetEntityType(): Client.SecureEntityTypeEnum | undefined {
    const state = useSelector((state: IRootReduxState) => state.Shell_SignIn);
    return state.secureEntityType;
}

export function createSetBusyAction(): layoutActions.IAction<undefined> {
    return layoutActions.actionFactory.setBusy();
}

export function useSetBusyAction(): void {
    const dispatch = useDispatch();
    dispatch(createSetBusyAction());
}

export function createClearBusyAction(): layoutActions.IAction<undefined> {
    return layoutActions.actionFactory.clearBusy();
}

export function useClearBusyAction(): void {
    const dispatch = useDispatch();
    dispatch(createClearBusyAction());
}

export function useIsDirty(): boolean {
    const state = useSelector((state: IRootReduxState) => state.Shell_Layout);
    return state.isDirty;
}
