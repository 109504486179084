import * as Models from "./models/models";
import { IPaymentViewModel } from "./models/paymentViewModel";
// api
import * as ClientFactory from "../../../api/clientFactory";
import * as callHelper from "../../../api/callHelper";
import * as Client from "../../../api/Client";
// common
import * as LogHelper from "../../../common/LogHelper";
import * as GlobalHelpers from "../../../common/GlobalHelpers";

export async function getCreditCardSurcharge(viewModel: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createCompanyFinanceClient();

        const response = await callHelper.call(() =>
            client.getCompanyFinanceCreditCardSurcharge(
                new Client.GetCompanyFinanceCreditCardSurchargeRequestDto({
                    financialNotifications: [
                        new Client.GetCompanyFinanceCreditCardSurchargeFinancialNotificationDto({
                            financialNotificationId: vm.financialNotificationId!,
                            versionNumber: vm.financialNotification!.versionNumber!,
                        }),
                    ],
                    secureTransactionToken: vm.paymentViewModel!.secureTransactionToken!,
                })
            )
        );

        if (response.IsSuccess) {
            // success
            setViewModel((vm = vm.refreshCreditCardSurcharge(response.Response!)));
        } else if (response.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            response.ShowToastNotification();
            setViewModel((vm = vm.setStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

interface IResponse {
    statusMessages?: Client.StatusMessagesDto;
}

export async function createPayment(viewModel: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    const paymentVm: IPaymentViewModel = vm.paymentViewModel!;
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        let result: callHelper.CallResult<IResponse>;
        if (paymentVm.paymentMethod === Client.Payment2MethodEnum.CreditCard) {
            result = await makeCreditCardPaymentRequest(vm);
        } else {
            result = await makePaymentRequest(vm);
        }

        if (result.IsSuccess) {
            // success
            const r = result.Response!;
            if (r.statusMessages?.isSuccess) {
                const client = await ClientFactory.createCompanyFinanceClient();
                const getDetailsResponse = await callHelper.simpleCall(() =>
                    client.getCompanyFinanceFnDetails(new Client.GetCompanyFinanceFnDetailsRequestDto({ financialNotificationId: vm.financialNotificationId! }))
                );
                vm = vm.refreshDetails(getDetailsResponse);
            }
            setViewModel((vm = vm.setStatusMessages(r.statusMessages)));
        } else if (result.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // all other errors
            result.ShowToastNotification();
            setViewModel((vm = vm.setStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }

    async function makePaymentRequest(vm: Models.IRootViewModel): Promise<callHelper.CallResult<IResponse>> {
        const client = await ClientFactory.createCompanyFinanceClient();
        const request = new Client.CreateCompanyFinanceFnDetailsPaymentRequestDto({
            financialNotificationId: vm.financialNotification.financialNotificationId,
            versionNumber: vm.financialNotification.versionNumber,
            paymentMethod: paymentVm.paymentMethod!,
            paymentDate: paymentVm.paymentMadeDate!,
            paymentDateOffsetMinutes: GlobalHelpers.getDateOffsetMinutes(paymentVm.paymentMadeDate)!,
            receivedAmount: paymentVm.receivedAmount!,
            bankName: paymentVm.paymentMethod === Client.Payment2MethodEnum.DirectDeposit ? paymentVm.bankName : undefined,
            bankReceiptNumber: paymentVm.paymentMethod === Client.Payment2MethodEnum.DirectDeposit ? paymentVm.bankReceiptNumber : undefined,
            chequeNumber: paymentVm.paymentMethod === Client.Payment2MethodEnum.Cheque ? paymentVm.chequeNumber : undefined,
            comments: paymentVm.comments,
        });
        return await callHelper.call(() => client.createCompanyFinanceFnDetailsPayment(request));
    }

    async function makeCreditCardPaymentRequest(vm: Models.IRootViewModel): Promise<callHelper.CallResult<IResponse>> {
        const client = await ClientFactory.createCompanyFinanceClient();
        const request = new Client.CreateCompanyFinanceFnDetailsCreditCardPaymentRequestDto({
            financialNotificationId: vm.financialNotification.financialNotificationId,
            versionNumber: vm.financialNotification.versionNumber,
            comments: paymentVm.comments,
            secureTransactionToken: paymentVm.secureTransactionToken,
        });
        return await callHelper.call(() => client.createCompanyFinanceFnDetailsCreditCardPayment(request));
    }
}

export async function getFinancialNotificationDetails(viewModel: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));
        const client = await ClientFactory.createCompanyFinanceClient();
        const response = await callHelper.simpleCall(() => client.getCompanyFinanceFnDetails(new Client.GetCompanyFinanceFnDetailsRequestDto({ financialNotificationId: vm.financialNotificationId })));
        setViewModel((vm = vm.refreshDetails(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}
