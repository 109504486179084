// framework
import { useEffect, useState } from "react";
// model
import * as Models from "../models/models";
import { IPaymentViewModel } from "../models/paymentViewModel";
import * as Validation from "../../../../common/validation/ValidationModel";
// api
import * as Client from "../../../../api/Client";
// views
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
// common
import CustomCheckoutController from "../../../../common/bambora/CustomCheckoutController";
import { toNoptaNumberString } from "../../../../common/GlobalHelpers";
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as actions from "../actions";

export default function MakePaymentDetailsView(props: {
    viewModel: Models.IRootViewModel;
    setViewModel: (vm: Models.IRootViewModel) => void;
    validator: Validation.IValidationModel;
}): React.ReactElement {
    let vm = props.viewModel;
    let v = props.validator;

    const [successMessage, setSuccessMessage] = useState<string>();
    const [state, setState] = useState<IPaymentViewModel>({
        paymentMethod: Client.Payment2MethodEnum.CreditCard,
        receivedAmount: vm.financialNotification.outstandingAmount,
        bankName: undefined,
        bankReceiptNumber: undefined,
        chequeNumber: undefined,
        comments: undefined,
        creditCardSurchargeAmount: undefined,
        secureTransactionToken: undefined,
    });

    useEffect(() => {
        props.setViewModel(vm.refreshPaymentDetails({ ...state }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (vm.paymentViewModel?.creditCardSurchargeAmount || vm.paymentViewModel?.creditCardSurchargeAmount === 0) {
            let surcharge = vm.paymentViewModel?.creditCardSurchargeAmount;
            setSuccessMessage(
                "Total Amount Payable is " +
                    toNoptaNumberString(state.receivedAmount! + surcharge, "c") +
                    ", which includes a Surcharge of " +
                    toNoptaNumberString(surcharge, "c") +
                    ". Press Submit to proceed with payment."
            );
            const newState = { ...state, ...vm.paymentViewModel };
            setState(newState);
            props.setViewModel(vm.refreshPaymentDetails({ ...newState }));
        } else {
            setSuccessMessage(undefined);
        }
    }, [vm.paymentViewModel?.creditCardSurchargeAmount, vm.paymentViewModel?.secureTransactionToken]); // eslint-disable-line react-hooks/exhaustive-deps

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function onTokenSet(stt: string | undefined) {
        setSuccessMessage(undefined);

        const newState = { ...state, secureTransactionToken: stt, creditCardSurchargeAmount: undefined };
        setState(newState);
        if (stt) {
            actions.getCreditCardSurcharge(vm.refreshPaymentDetails(newState), props.setViewModel);
        } else {
            props.setViewModel(vm.refreshPaymentDetails(newState));
        }
    }

    function updateViewModel() {
        props.setViewModel(vm.refreshPaymentDetails({ ...state }));
    }

    return (
        <>
            <Forms.Group>
                <Forms.TextArea
                    id="comments"
                    label="Comments"
                    rows={5}
                    maxLength={1000}
                    value={state.comments}
                    valid={v.applyValidity("MakePayment.Comments")}
                    onChange={(value) => onChanged({ comments: value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.Comments" validationModel={v} />
            </Forms.Group>
            <div className="card-body border mb-4 pb-0">
                <h4 className="card-title">Credit Card Details</h4>
                <CustomCheckoutController onTokenChanged={(stt) => onTokenSet(stt)} message={successMessage} />
                <ValidationMessageView name="MakePayment.VerifyDetails" validationModel={v} />
            </div>
        </>
    );
}
