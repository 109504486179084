import * as Models from "./models/models";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as callHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as LogHelper from "../../../../common/LogHelper";
import * as documentHelper from "../../../../common/documentHelper";

export async function getSeismicLines(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const response = await callHelper.simpleCall(() =>
            client.getGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLines(
                new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesRequestDto({
                    surveyId: vm.surveyId!,
                })
            )
        );

        setViewModel((vm = vm.refreshSurveySeismicLines(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function createSeismicLine(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const createResponse = await callHelper.call(() =>
            client.createGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLine(
                new Client.CreateGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineRequestDto({
                    surveyId: vm.surveyId!,
                    surveyVersionNumber: vm.surveyVersionNumber!,
                    surveyAcquisitionId: vm.selectedSeismicLine!.surveyAcquisition!.id!,
                    lineName: vm.selectedSeismicLine!.lineName,
                    lineLength: vm.selectedSeismicLine!.lineLength,
                    firstShotPoint: vm.selectedSeismicLine!.firstShotPoint,
                    lastShotPoint: vm.selectedSeismicLine!.lastShotPoint,
                    nominalShotInterval: vm.selectedSeismicLine!.nominalShotInterval,
                    shotIncrement: vm.selectedSeismicLine!.shotIncrement,
                    publicComments: vm.selectedSeismicLine!.publicComments,
                })
            )
        );

        if (createResponse.IsSuccess) {
            // success
            if (createResponse.Response!.statusMessages!.isSuccess) {
                // success
                const refreshResponse = await callHelper.simpleCall(() =>
                    client.getGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLines(
                        new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesRequestDto({
                            surveyId: vm.surveyId!,
                        })
                    )
                );
                setViewModel((vm = vm.refreshSurveySeismicLines(refreshResponse).refreshStatusMessages(createResponse.Response!.statusMessages)));
            } // successful response, failed operation.
            else {
                setViewModel((vm = vm.refreshStatusMessages(createResponse.Response!.statusMessages)));
            }
        } else if (createResponse.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            createResponse.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function updateSeismicLine(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const updateResponse = await callHelper.call(() =>
            client.updateGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLine(
                new Client.UpdateGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineRequestDto({
                    surveyId: vm.surveyId!,
                    surveyVersionNumber: vm.surveyVersionNumber!,
                    surveyAcquisitionId: vm.selectedSeismicLine!.surveyAcquisition!.id!,
                    lineId: vm.selectedSeismicLine!.id!,
                    lineName: vm.selectedSeismicLine!.lineName,
                    lineLength: vm.selectedSeismicLine!.lineLength,
                    firstShotPoint: vm.selectedSeismicLine!.firstShotPoint,
                    lastShotPoint: vm.selectedSeismicLine!.lastShotPoint,
                    nominalShotInterval: vm.selectedSeismicLine!.nominalShotInterval,
                    shotIncrement: vm.selectedSeismicLine!.shotIncrement,
                    publicComments: vm.selectedSeismicLine!.publicComments,
                })
            )
        );

        if (updateResponse.IsSuccess) {
            // success
            if (updateResponse.Response!.statusMessages!.isSuccess) {
                const refreshResponse = await callHelper.simpleCall(() =>
                    client.getGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLines(
                        new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesRequestDto({
                            surveyId: vm.surveyId!,
                        })
                    )
                );
                setViewModel((vm = vm.refreshSurveySeismicLines(refreshResponse).refreshStatusMessages(updateResponse.Response!.statusMessages)));
            } // successful response, failed operation.
            else {
                setViewModel((vm = vm.refreshStatusMessages(updateResponse.Response!.statusMessages)));
            }
        } else if (updateResponse.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            updateResponse.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function deleteSeismicLine(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const deleteResponse = await callHelper.call(() =>
            client.deleteGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLine(
                new Client.DeleteGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineRequestDto({
                    surveyId: vm.surveyId!,
                    surveyVersionNumber: vm.surveyVersionNumber!,
                    surveyAcquisitionId: vm.selectedSeismicLine!.surveyAcquisition!.id!,
                    lineId: vm.selectedSeismicLine!.id!,
                })
            )
        );

        if (deleteResponse.IsSuccess) {
            // success
            if (deleteResponse.Response!.statusMessages!.isSuccess) {
                const refreshResponse = await callHelper.simpleCall(() =>
                    client.getGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLines(
                        new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesRequestDto({
                            surveyId: vm.surveyId!,
                        })
                    )
                );

                setViewModel((vm = vm.refreshSurveySeismicLines(refreshResponse).refreshStatusMessages(deleteResponse.Response!.statusMessages)));
            }
            // successful response, failed operation.
            else {
                setViewModel((vm = vm.refreshStatusMessages(deleteResponse.Response!.statusMessages)));
            }
        } else if (deleteResponse.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            deleteResponse.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function exportSeismicLines(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const response = await callHelper.call(() =>
            client.exportGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLines(
                new Client.ExportGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesRequestDto({
                    surveyId: vm.surveyId!,
                })
            )
        );

        if (response.IsSuccess) {
            // success
            const downloadResponse = response.Response!;
            documentHelper.download(downloadResponse.fileName!, downloadResponse.mimeType!, downloadResponse.content!);
        } else if (response.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            response.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}
