// framework
import { useEffect, useState } from "react";
// kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
// model
import * as Models from "../models/models";
import { IPaymentViewModel } from "../models/paymentViewModel";
// api
import * as Client from "../../../../api/Client";
// common
import * as Validation from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
import * as Forms from "../../../../common/forms/BootstrapForms";

export default function MakePaymentDetailsView(props: {
    viewModel: Models.IRootViewModel;
    setViewModel: (vm: Models.IRootViewModel) => void;
    validator: Validation.IValidationModel;
}): React.ReactElement {
    let vm = props.viewModel;
    let v = props.validator;

    const [state, setState] = useState<IPaymentViewModel>({
        paymentMethod: Client.Payment2MethodEnum.DirectDeposit,
        paymentMadeDate: undefined,
        receivedAmount: vm.financialNotification.outstandingAmount,
        bankName: undefined,
        bankReceiptNumber: undefined,
        chequeNumber: undefined,
        comments: undefined,
        creditCardSurchargeAmount: undefined,
        secureTransactionToken: undefined,
    });

    useEffect(() => {
        props.setViewModel(vm.refreshPaymentDetails({ ...state }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function onChanged(values: any) {
        setState({ ...state, ...values });
    }

    function updateViewModel() {
        props.setViewModel(vm.refreshPaymentDetails({ ...state }));
    }

    return (
        <>
            <Forms.Group>
                <Forms.Label htmlFor="paymentMadeDate">Payment Made</Forms.Label>
                <DatePicker
                    id="paymentMadeDate"
                    value={state.paymentMadeDate}
                    valid={v.applyValidityForKendo("MakePayment.PaymentMadeDate")}
                    onChange={(e) => {
                        onChanged({ paymentMadeDate: e.value });
                    }}
                    max={new Date()}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.PaymentMadeDate" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.Label htmlFor="receivedAmount">Payment Amount</Forms.Label>
                <NumericTextBox
                    id="receivedAmount"
                    value={state.receivedAmount}
                    valid={v.applyValidityForKendo("MakePayment.ReceivedAmount")}
                    format="c2"
                    onChange={(e) => onChanged({ receivedAmount: e.value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.ReceivedAmount" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextInput
                    id="bankReceiptNumber"
                    label="Bank Receipt Number"
                    maxLength={100}
                    value={state.bankReceiptNumber}
                    valid={v.applyValidity("MakePayment.BankReceiptNumber")}
                    onChange={(value) => onChanged({ bankReceiptNumber: value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.BankReceiptNumber" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextInput
                    id="bankName"
                    label="Bank"
                    maxLength={100}
                    value={state.bankName}
                    valid={v.applyValidity("MakePayment.BankName")}
                    onChange={(value) => onChanged({ bankName: value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.BankName" validationModel={v} />
            </Forms.Group>
            <Forms.Group>
                <Forms.TextArea
                    id="comments"
                    label="Comments"
                    rows={5}
                    maxLength={1000}
                    value={state.comments}
                    valid={v.applyValidity("MakePayment.Comments")}
                    onChange={(value) => onChanged({ comments: value })}
                    onBlur={updateViewModel}
                />
                <ValidationMessageView name="MakePayment.Comments" validationModel={v} />
            </Forms.Group>
        </>
    );
}
