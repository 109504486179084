// api
import * as Client from "../../../../../../../api/Client";
// common
import * as Forms from "../../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../../common/GlobalHelpers";
// views
import DataMonitoringItemsCategoryView from "./DataMonitoringItemsCategoryView";
import ReturnDataMonitoringItemsCategoryView from "./ReturnDataMonitoringItemsCategoryView";

interface IProps {
    activityType?: Client.AuthorisationActivityTypeEnum;
    category: Client.GetGeoscienceAustraliaAuthorisationsAuthorisationDetailsResponseCategoryDetailsDto;
    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;
    canViewDataSubmissions: boolean;
}

export default function SamplingExportCategoryView(props: IProps): JSX.Element {
    const { activityType, category, canViewBoreholes, canViewAcquisitions, canViewProjects, canViewDataSubmissions } = props;

    return (
        <>
            <Forms.Row>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField
                        id="sampleToBeReturnedField"
                        label="Sample to be returned"
                        value={category.samplingExportDetails?.sampleToBeReturned === undefined ? "Unknown" : category.samplingExportDetails?.sampleToBeReturned ? "Yes" : "No"}
                    />
                </Forms.QuarterColumn>
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField id="reportingDueDateSelectionField" label="Reporting Due Date Selection" value={!category.samplingExportDetails?.reportingDueMonths ? "Custom" : "Month"} />
                </Forms.QuarterColumn>
                {category.samplingExportDetails?.reportingDueMonths && (
                    <Forms.QuarterColumn>
                        <Forms.ReadonlyField id="reportingDueMonthsField" label="Reporting Due Months" value={category.samplingExportDetails?.reportingDueMonths?.toString()} />
                    </Forms.QuarterColumn>
                )}
                <Forms.QuarterColumn>
                    <Forms.ReadonlyField id="reportingDueDateField" label="Reporting Due Date" value={GlobalHelpers.toNoptaDateString(category.samplingExportDetails?.reportingDueDate)} />
                </Forms.QuarterColumn>
                {!category.samplingExportDetails?.reportingDueMonths && <Forms.QuarterColumn />}
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea id="sampleDepthField" label="Sample Depths" value={category.samplingExportDetails?.sampleDepth} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea
                        id="analysisTypeField"
                        label="Analysis Type(s)"
                        value={category.samplingExportDetails?.analysisTypeNames.length === 0 ? undefined : category.samplingExportDetails?.analysisTypeNames.join(", ")}
                    />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyTextArea
                        id="sampleTypeField"
                        label="Sample Type(s)"
                        value={category.samplingExportDetails?.sampleTypeNames.length === 0 ? undefined : category.samplingExportDetails?.sampleTypeNames.join(", ")}
                    />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="exportCountryField" label="Country for Export" value={category.samplingExportDetails?.exportToCountryName} />
                </Forms.HalfColumn>
                <Forms.HalfColumn>
                    <Forms.ReadonlyField id="repositoryField" label="Repository" value={category.samplingExportDetails?.repositoryTypeName} />
                </Forms.HalfColumn>
            </Forms.Row>
            <DataMonitoringItemsCategoryView
                activityType={activityType}
                category={category}
                isExportCategory={true}
                canViewBoreholes={canViewBoreholes}
                canViewAcquisitions={canViewAcquisitions}
                canViewProjects={canViewProjects}
                canViewDataSubmissions={canViewDataSubmissions}
            />
            <ReturnDataMonitoringItemsCategoryView
                returnDataMonitoringItems={category.samplingExportDetails?.returnDataMonitoringItems!}
                canViewBoreholes={canViewBoreholes}
                canViewAcquisitions={canViewAcquisitions}
                canViewProjects={canViewProjects}
                canViewDataSubmissions={canViewDataSubmissions}
            />
        </>
    );
}
